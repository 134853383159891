'use client'

import { useTheme as useNextThemes } from 'next-themes'

import { Themes } from './constants'

const useTheme = () => {
	const { theme, setTheme } = useNextThemes()

	const toggle = () => {
		const newTheme = theme === Themes.DARK ? Themes.LIGHT : Themes.DARK
		return setTheme(newTheme)
	}

	return {
		toggle,
		theme: theme as Themes,
		isDark: theme === Themes.DARK,
		isLight: theme === Themes.LIGHT,
	}
}

type UseThemeReturn = ReturnType<typeof useTheme>

export { useTheme }
export type { UseThemeReturn }
