'use client'

import { useState } from 'react'
import superjson from 'superjson'
import { createTRPCReact } from '@trpc/react-query'
import { QueryClient, QueryClientProvider, type QueryClientConfig } from '@tanstack/react-query'
import { getFetch, httpLink, type CreateTRPCClientOptions } from '@trpc/client'

import type { AppRouter } from '@seeurcargo/api-web'

import type { Provider } from '~/lib/types'

import { getUrl } from './utils'

const queryOptions: QueryClientConfig = {
	defaultOptions: {
		queries: {
			staleTime: 10000,
			refetchOnMount: false,
			refetchOnReconnect: true,
			refetchOnWindowFocus: false,
		},
	},
}

const apiOptions: CreateTRPCClientOptions<AppRouter> = {
	links: [
		httpLink({
			url: getUrl(),
			transformer: superjson,
			headers() {
				return {
					'x-trpc-source': 'client',
				}
			},
			fetch: async (input, init?) => {
				const fetch = getFetch()
				return fetch(input, {
					...init,
					credentials: 'same-origin',
				})
			},
		}),
	],
}

const api = createTRPCReact<AppRouter>({
	overrides: {
		useMutation: {
			async onSuccess(opts) {
				await opts.originalFn()
				opts.queryClient.invalidateQueries()
			},
		},
	},
})

const createTRPCClient = () => api.createClient(apiOptions)
const createQueryClient = () => new QueryClient(queryOptions)

const TRPCProvider: Provider = ({ children }) => {
	const [trpcClient] = useState(createTRPCClient)
	const [queryClient] = useState(createQueryClient)

	return (
		<api.Provider
			client={trpcClient}
			queryClient={queryClient}
		>
			<QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
		</api.Provider>
	)
}

export { api, createTRPCClient, createQueryClient, TRPCProvider }
