'use client'

import { ThemeProvider as NextThemesProvider } from 'next-themes'
import type { ThemeProviderProps } from 'next-themes/dist/types'

import { Themes } from './constants'

const ThemeProvider = ({ children, ...themeProps }: ThemeProviderProps) => (
	<NextThemesProvider
		defaultTheme={Themes.DARK}
		{...themeProps}
	>
		{children}
	</NextThemesProvider>
)

export { ThemeProvider }
export type { ThemeProviderProps }
