const trpcEndpoint = '/api/trpc'

const getBaseUrl = () => {
	if (typeof window !== 'undefined') return ''
	if (process.env.VERCEL_URL) return `https://${process.env.VERCEL_URL}`
	return 'http://localhost:3000'
}

const getUrl = () => {
	return getBaseUrl() + trpcEndpoint
}

export { getUrl, getBaseUrl, trpcEndpoint }
