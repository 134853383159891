'use client'

import { Toaster } from 'sonner'

import type { Provider } from '~/lib/types'
import { TRPCProvider } from '~/lib/trpc/client.query'

import { ThemeProvider } from '~/client/features/theme'
import { useResponsive } from '~/client/hooks/useResponsive'

type ProvidersProps = {
	headers?: Headers
}

const Providers: Provider<ProvidersProps> = ({ children }) => {
	const { isTouch } = useResponsive()

	return (
		<TRPCProvider>
			<ThemeProvider>
				{children}
				<Toaster
					richColors
					theme="dark"
					visibleToasts={3}
					offset={isTouch ? '1rem' : ' 1.5rem'}
					position={isTouch ? 'top-center' : 'bottom-right'}
				/>
			</ThemeProvider>
		</TRPCProvider>
	)
}

export { Providers }
